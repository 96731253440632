import { Link, navigate } from "gatsby"
import moment from "moment"
import React, { Component } from "react"
import { connect } from "react-redux"
import Header from "../../components/base/Header"
import Overlay from "../../components/base/Overlay"
import SEO from "../../components/base/seo"
import Whatsapp from "../../components/marketing/Whatsapp"
import EventModel from "../../models/Event"
import { getEventWithId } from "../../store/actions/eventAction"
import { utilsStateInterface } from "../../store/reducers/utilsReducer"

interface Props {
  dashboard: boolean
  utilsStore: utilsStateInterface
}

interface State {
  loading: boolean
  visible: boolean
  event: EventModel
}

class EventEditor extends Component<Props> {
  state: State = {
    loading: true,
    visible: false,
    event: {
      id: "",
      name: "",
      imageToken: "",
      ended: false,
      tag: "",
      description: "",
      address: {
        name: "",
        lat: 0,
        lng: 0,
        geohash: "",
        district: "",
        state: "",
        country: "",
      },
      startDate: new Date(),
      endDate: new Date(),
      duration: "",
    },
  }

  componentDidMount = () => {
    this.handleGetPreviewData()
  }

  handleGetPreviewData = async () => {
    const searchParams = new URLSearchParams(window.location.search)
    const selectedId = searchParams.get("data")
    if (selectedId) {
      const eventData = await getEventWithId(
        selectedId,
        this.props.utilsStore.firebase
      )
      if (typeof eventData === "string") {
        this.handleBack()
      } else {
        this.setState({
          event: eventData,
          loading: false,
        })
      }
    } else {
      this.handleBack()
    }
  }

  handleModalVisible = (visible: boolean) => {
    this.setState({
      visible,
    })
  }

  handleBack() {
    navigate(this.props.dashboard ? "/dashboard/events" : "/events")
  }

  render() {
    let defaultWebUrl =
      "https://firebasestorage.googleapis.com/v0/b/arusoil-web-dev.appspot.com/o/events%2F"
    const isProd = process.env.GATSBY_FIREBASE_ENV === "production"

    if (isProd) {
      defaultWebUrl =
        "https://firebasestorage.googleapis.com/v0/b/arusoil-web.appspot.com/o/events%2F"
    }
    return (
      <div>
        {!this.props.dashboard && (
          <>
            <Whatsapp />
            <SEO
              title="Arus Oil - Our Events"
              description="Explore the dynamic world of Arus Oil through our exclusive events. From industry conferences to community engagements, delve into the latest updates and initiatives driving our mission forward. Stay connected with Arus Oil"
            />
            <Header page={"Events"} />
          </>
        )}
        <div className="py-10">
          <div className="max-w-7xl flex flex-col mx-auto px-4 sm:px-6 lg:px-8">
            <div className="md:flex md:items-center md:justify-between w-full">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">
                <Link
                  className="cursor-pointer text-3xl font-bold leading-tight text-arusgreen-600 hover:text-arusgreen-300"
                  to={this.props.dashboard ? "/dashboard/events" : "/events"}
                >
                  Event
                </Link>
                {" /"} Details
              </h1>
            </div>

            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8"></div>
            <div className="mt-8">
              <Overlay loading={this.state.loading} text="Verifying Events" />
              <div className="block mt-2">
                <div className="flex flex-col mt-2 px-4 sm:px-0">
                  <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-5 flex flex-row sm:px-6">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          {this.state.event.name}
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                          {this.state.event.description}
                        </p>
                      </div>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                      <dl className="sm:divide-y sm:divide-gray-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Tag
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex flex-row">
                            <p className="mr-2 bg-primary-600 py-1 px-2 text-white rounded-md font-semibold">
                              {this.state.event.tag}
                            </p>
                          </dd>
                        </div>

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Event Start Date
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {moment(
                              this.state.event.startDate.seconds * 1000
                            ).format("DD/MM/YY")}
                          </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Event End Date
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {moment(
                              this.state.event.endDate.seconds * 1000
                            ).format("DD/MM/YY")}
                          </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Duration
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {this.state.event.duration}
                          </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Address
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {this.state.event.address.name},{" "}
                            {this.state.event.address.state},{" "}
                            {this.state.event.address.district},{" "}
                            {this.state.event.address.country}
                          </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Event status
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {this.state.event.ended
                              ? "Event has ended"
                              : "Event still progressing"}
                          </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Event Photo
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <img
                              className="object-cover h-full w-full"
                              src={`${defaultWebUrl}${this.state.event.id}%2Fimage.jpg?alt=media&token=${this.state.event.imageToken}`}
                              alt=""
                            />
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilsStore: state.utilsStore,
  }
}

export default connect(mapStateToProps)(EventEditor)
