import EventModel from "../../models/Event"
import { Address } from "../../models/User"

export const geofire = require("geofire-common")

export const eventDataLimit: number = 10

export const getEventWithPagination = (
  paginationStartAfter: Date | string,
  firebase: any,
  paginationSearch?: Address
) => {
  return async (dispatch: any, getState: any) => {
    try {
      let eventSnapshot

      const eventList: EventModel[] = getState().eventStore.events
      const newEventList: EventModel[] = []

      const firebaseQuery = firebase
        .firestore()
        .collection("events")
        .where("ended", "==", false)

      updateEventLoadingState(dispatch, true)

      if (
        paginationSearch?.lat &&
        paginationSearch.lng &&
        paginationSearch.geohash
      ) {
        const center = [paginationSearch.lat, paginationSearch.lng]
        const radiusInM = 50 * 1000

        const bounds = geofire.geohashQueryBounds(center, radiusInM)

        const boundSnapshot = []

        for (const b of bounds) {
          const boundQuery = firebaseQuery
            .orderBy("address.geohash")
            .startAt(b[0])
            .endAt(b[1])

          boundSnapshot.push(boundQuery.get())
        }

        if (boundSnapshot) {
          const boundData = await Promise.all(boundSnapshot)
          const matchingDocs = []

          for (const snap of boundData) {
            for (const doc of snap.docs) {
              const lat = doc.get("address.lat")
              const lng = doc.get("address.lng")
              const distanceInKm = geofire.distanceBetween(
                [lat as number, lng as number],
                center
              )
              const distanceInM = distanceInKm * 1000
              if (distanceInM <= radiusInM) {
                matchingDocs.push(doc)
              }
            }
          }

          if (matchingDocs) {
            matchingDocs.forEach((eachDoc: any) => {
              const eachEvent = eachDoc.data() as EventModel
              newEventList.push(eachEvent)
            })
          }
        }
      } else {
        const eventQuery = firebaseQuery
          .orderBy("startDate", "desc")
          .limit(eventDataLimit)

        if (paginationStartAfter) {
          eventSnapshot = await eventQuery
            .startAfter(paginationStartAfter)
            .get()
        } else {
          eventSnapshot = await eventQuery.get()
        }

        if (eventSnapshot) {
          eventSnapshot.forEach((eachDoc: any) => {
            const eachEvent = eachDoc.data() as EventModel
            newEventList.push(eachEvent)
          })
        }
      }
      if (newEventList.length > 0) {
        let lastCursor: Date | string = ""
        if (newEventList.length === eventDataLimit) {
          lastCursor =
            paginationSearch?.lat &&
            paginationSearch.lng &&
            paginationSearch.geohash
              ? ""
              : newEventList[eventDataLimit - 1].startDate
        }

        dispatch({
          type: "UPDATE_EVENT_LIST",
          payload: {
            events: eventList.concat(newEventList),
            lastCursor: lastCursor,
          },
        })
      } else {
        dispatch({
          type: "UPDATE_EVENT_LIST",
          payload: {
            events: eventList,
            lastCursor: "",
          },
        })
      }

      updateEventLoadingState(dispatch, false)
    } catch (err) {
      updateEventLoadingState(dispatch, false)
      return err.message
    }
  }
}

export const getEventWithId = async (selectedId: string, firebase: any) => {
  try {
    const eventQuery = await firebase
      .firestore()
      .collection("events")
      .doc(selectedId)
      .get()
    if (eventQuery.exists) {
      const currentEvent = eventQuery.data() as EventModel
      return currentEvent
    } else {
      return "Event cannot be found"
    }
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues"
  }
}

export const updateEventLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_EVENT_LOADING",
    payload: {
      loading: loading,
    },
  })
}

export const clearEvent = () => {
  return (dispatch: any) => {
    dispatch({
      type: "UPDATE_EVENT_LIST",
      payload: {
        events: [],
      },
    })
  }
}
