import React, { Component } from "react"
import EventsEditor from "../components/base/eventsEditor"

class EventEditor extends Component {
  render() {
    return <EventsEditor dashboard={false} />
  }
}

export default EventEditor
